import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import React, {FC, useState, useEffect} from 'react'
import {getTransactionByEndDate} from '../transaction/list/core/_requests'
import moment from 'moment'

const DashboardWrapper = () => {
  const intl = useIntl()

  const [data, setData] = React.useState([])

  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    // Her 1 saniyede bir sürekli olarak şu anın zamanını güncelle
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  console.log(currentTime)

  const getElapsedTime = (startTime: Date): string => {
    const startDate = new Date(startTime)
    const timeDifference = currentTime.getTime() - startDate.getTime()
    const secondsElapsed = Math.floor(timeDifference / 1000)
    const hours = Math.floor(secondsElapsed / 3600)
    const minutes = Math.floor((secondsElapsed % 3600) / 60)
    return `${hours} saat ${minutes} dakika`
  }

  useEffect(() => {
    getTransactionByEndDate().then((res: any) => {
      setData(res?.data)
    })
  }, [])
  console.log(data)
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <div>
        <div className='row g-6 g-xl-9 mb-6 mb-xl-9 mt-5 px-5'>
          {data?.map((item: any) => {
            return (
              <div className='col-md-6 col-lg-3 col-xl-3 mb-5 '>
                <div
                  className='card h-100 mb-5 '
                  style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)'}}
                >
                  <div className='pb-4 d-flex justify-content-center text-center flex-column p-2 '>
                    <div className='text-gray-800 text-hover-primary d-flex flex-column'>
                      <div className=' ' style={{height: '10px'}}>
                        {/* <img src={img} className='theme-dark-show' alt='' /> */}
                      </div>

                      <a className='fs-1 fw-bold mt-4 mb-1'> {item?.children?.name}</a>
                      <Link
                        className='fs-6 fw-bold text-warning  mb-5'
                        to={`/customer/userProfile?userId=${item?.children?.applicationUserId}`}
                      >
                        <a className='fs-6 fw-bold text-warning  '>
                          {' '}
                          Müşteri:{item?.children?.applicationUser?.name}
                        </a>
                      </Link>
                    </div>

                    <div className='fs-6 fw-semibold text-gray-400 mb-1 '>
                      Başlangıç Saati : {moment(item?.startTime).format('YYYY-MM-DD HH:mm')}
                    </div>
                    <div className='fs-6 fw-semibold text-gray-400 mb-1  '>
                      Telefon-1 : {item?.children?.applicationUser?.phone1}
                    </div>

                    <div className='fs-6 fw-semibold text-gray-400  mb-1 '>
                      Telefon-2 : {item?.children?.applicationUser?.phone2}
                    </div>

                    <div className='fs-7 fw-semibold text-gray-400 mt-4   '>
                      <div key={item.id}>
                        {/* <h1>Tarih: {item?.startTime.toISOString()}</h1> */}
                        <h2 className='fs-4'>Süre: {getElapsedTime(item?.startTime)}</h2>
                      </div>
                    </div>

                    <div className=' ' style={{height: '10px'}}>
                      {/* <img src={img} className='theme-dark-show' alt='' /> */}
                    </div>
                  </div>
                  <div
                    className='d-flex justify-content-center text-center'
                    style={{justifyContent: 'center !important', alignItems: 'center !important'}}
                  >
                    <Link
                      className='btn btn-sm btn-warning btn-active-light-primary w-50 '
                      to={`/adisyon?userId=${item?.children?.applicationUserId}&childrenId=${item?.childrenId}&adisyonId=${item?.id}`}
                    >
                      Adisyon Detay
                    </Link>
                  </div>
                </div>
              </div>
            )
          })}
          {data.length == 0 ? <h2 className='fs-4'>Müşteri Bulunmamaktadır</h2> : null}
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
