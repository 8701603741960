/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

import qs from 'qs'
import {useState, useEffect} from 'react'
import Cookies from 'js-cookie'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  const token = localStorage.getItem('kt-auth-react-v')
  const token2 = sessionStorage.getItem('token')
  const cookie = Cookies.get('token')
  console.log(cookie)

  const [data, setData] = useState<any>(undefined)
  const [fbid, setFbid] = useState<any>(undefined)
  const [device, setDevice] = useState<any>(undefined)

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).data ?? undefined
    setData(qsd)
    const qsd2 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).fbid ?? undefined
    setFbid(qsd2)
    const qsd3 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).cihaz ?? undefined
    setDevice(qsd3)

    console.log(qsd)
    console.log(qsd2)
    console.log(currentUser)
  }, [])
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes {...(currentUser?.menus || [])} />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
