/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain(menus: Array<string>) {
  const intl = useIntl()
  const uniqueTags: string[] = []

  Object.keys(menus).map((fullstr: any) => {
    if (
      uniqueTags.indexOf(menus[fullstr].split('.')[0].split('_')[1]) === -1 &&
      menus[fullstr].split('.')[0].split('_')[0] != 'main'
    ) {
      uniqueTags.push(menus[fullstr].split('.')[0].split('_')[1])
    }
  })

  console.log(menus)
  return (
    <>
      {menus && (
        <div
          className='menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold overflow-y-auto vh-100 '
          id='#kt_aside_menu'
          data-kt-menu='true'
          style={{paddingBottom: '170px'}}
        >
          <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-house'
          />
          {Object.keys(menus).map((fullstr: any) => {
            return (
              menus[fullstr].split('.')[0].split('_')[0] == 'main' && (
                <AsideMenuItem
                  to={'/' + menus[fullstr].split('.')[1].split('_')[0]}
                  icon='/media/icons/duotune/arrows/arr001.svg'
                  title={intl.formatMessage({
                    id: 'MENU.' + menus[fullstr].split(',')[0].split('.')[1].split('_')[1],
                  })}
                  fontIcon={menus[fullstr].split(',')[1]}
                />
              )
            )
          })}
          {uniqueTags.map((tag: any) => (
            <AsideMenuItemWithSubMain
              to='/library'
              title={intl.formatMessage({id: 'MENU.' + tag})}
              fontIcon='bi-sticky'
              // icon='/media/icons/duotune/arrows/arr001.svg'
              bsTitle={intl.formatMessage({id: 'MENU.' + tag})}
            >
              {Object.keys(menus).map(
                (fullstr: any) =>
                  menus[fullstr].split('.')[0].split('_')[1] == tag && (
                    <AsideMenuItem
                      to={'/' + menus[fullstr].split('.')[1].split('_')[0]}
                      icon='/media/icons/duotune/arrows/arr001.svg'
                      title={intl.formatMessage({
                        id: 'MENU.' + menus[fullstr].split(',')[0].split('.')[1].split('_')[1],
                      })}
                      fontIcon={menus[fullstr].split(',')[1]}
                    />
                  )
              )}
            </AsideMenuItemWithSubMain>
          ))}
          {/* <AsideMenuItemWithSubMain
        to='/builder'
        title='Resources'
        bsTitle='Resources'
        fontIcon='bi-gear'
      >
        <AsideMenuItem to='/builder' title='Layout builder' fontIcon='bi-layers fs-3' />
        <AsideMenuItem
          to={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          outside={true}
          title={`Changelog ${process.env.REACT_APP_VERSION}`}
          fontIcon='bi-card-text fs-3'
        />
      </AsideMenuItemWithSubMain> */}

          {/* <AsideMenuItemWithSubMain
        to='/crafted/pages'
        title='Crafted'
        fontIcon='bi-file-text'
        bsTitle='Crafted'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/profile/overview'
            title='Overview'
            bsTitle='Overview'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/projects'
            title='Projects'
            bsTitle='Projects'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            bsTitle='Campaigns'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            bsTitle='Documents'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
            bsTitle='Connections'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
            bsTitle='Horizontal'
          />
          <AsideMenuItem
            to='/crafted/pages/wizards/vertical'
            title='Vertical'
            bsTitle='Vertical'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/widgets/lists'
            title='Lists'
            bsTitle='Lists'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/statistics'
            title='Statistics'
            bsTitle='Statistics'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/charts'
            title='Charts'
            bsTitle='Charts'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/mixed'
            title='Mixed'
            bsTitle='Mixed'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/tables'
            title='Tables'
            bsTitle='Tables'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/feeds'
            title='Feeds'
            bsTitle='Feeds'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/error' title='Errors' hasBullet={true}>
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to='/apps/user-management/users'
          title='User management'
          hasBullet={true}
          bsTitle='User management'
        />
      </AsideMenuItemWithSubMain> */}
        </div>
      )}
    </>
  )
}
