import React, {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'

import {useIntl} from 'react-intl'
import {
  defaultCreateAppData,
  ICreateAppData,
} from '../../../_metronic/partials/modals/create-app-stepper/IAppModels'
import {StepperComponent} from '../../../_metronic/assets/ts/components'
import * as Yup from 'yup'
import {KTSVG} from '../../../_metronic/helpers'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {isNotEmpty} from '../../../_metronic/helpers'
import {getTransactionById, updateTransaction} from '../transaction/list/core/_requests'

type Props = {
  item: any
  show: boolean
  handleClose: () => void
  //   setTrigger: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const editchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const UpdateDateModal = ({item, show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [data, setData] = useState<ICreateAppData>(defaultCreateAppData)
  const [hasError, setHasError] = useState(false)
  const intl = useIntl()
  const [startDate, setStartDate] = useState<any>(undefined)
  const [endDate, setEndDate] = useState<any>(undefined)
  //   const [trigger, setTrigger] = React.useState(0)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.appName || !data.appBasic.appType) {
      return false
    }
    return true
  }

  const checkAppDataBase = (): boolean => {
    if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
      return false
    }

    return true
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.current.getCurrentStepIndex() === 3) {
      if (!checkAppDataBase()) {
        setHasError(true)
        return
      }
    }

    stepper.current.goNext()
  }

  const submit = () => {
    window.location.reload()
  }

  const [surveys, setsurveys] = React.useState([])

  useEffect(() => {}, [])

  const [placeForEdit] = useState<any>({
    name: undefined,
    content: undefined,
    duration: undefined,
    surveyId: undefined,
    isExercise: undefined,
    privateCourse: undefined,
    isVisualCourse: undefined,

    ...item,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      //   refetch()
    }
    // setItemIdForUpdate(undefined)
  }

  //   const formik = useFormik({
  //     initialValues: placeForEdit,
  //     // validationSchema: editchema,
  //     onSubmit: async (values, {setSubmitting}) => {
  //       setSubmitting(true)
  //       try {
  //         if (isNotEmpty(values.id)) {
  //           await updateCourse(values).then((res: any) => {
  //             handleClose()
  //           })
  //         } else {
  //           await updateCourse(values)
  //         }
  //       } catch (ex) {
  //         console.error(ex)
  //       } finally {
  //         setSubmitting(true)
  //         cancel(true)
  //       }
  //     },
  //   })

  console.log(startDate)

  const ChangeDates = () => {
    getTransactionById(item).then((res: any) => {
      console.log(res)
      let x = res
      x.startTime = startDate
      x.endTime = endDate

      updateTransaction(x).then((res: any) => {
        handleClose()
        window.location.reload()
      })
    })
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='modal-header'>
        <h2> Saati Düzenle</h2>

        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10 '>
        <div className='px-10  '>
          <div className='card-body px-2 py-9'>
            <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Başlangıç Tarihi</label>
              <input
                type='datetime-local'
                name='theDate'
                //   value={moment(formik.values.theDate).format('YYYY-MM-DD HH:mm')}
                onChange={(e: any) => {
                  setStartDate(e.target.value)
                }}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
              />
            </div>
            {/* <div className='fv-row mb-7'>
              <label className='required fw-bold fs-6 mb-2'>Bitiş Tarihi</label>
              <input
                type='datetime-local'
                name='theDate'
                //   value={moment(formik.values.theDate).format('YYYY-MM-DD HH:mm')}
                onChange={(e: any) => {
                  setEndDate(e.target.value)
                }}
                className='form-control form-control-solid mb-3 mb-lg-0'
                autoComplete='off'
              />
            </div> */}
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-items-modal-action='submit'
              data-kt-menu-dismiss='true'
              onClick={(e: any) => ChangeDates()}
            >
              <span className='indicator-label'> {intl.formatMessage({id: 'MODALFORM.SAVE'})}</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {UpdateDateModal}
