import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper as DashboardPage} from '../pages/dashboard/DashboardWrapper'
import {AdisyonDetail} from '../pages/dashboard/AdisyonDetail'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = (menus: Array<string>) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ModelListPage = lazy(() => import('../pages/modellist/Page'))
  const MethodListPage = lazy(() => import('../pages/methodlist/Page'))
  const RoleMethodListPage = lazy(() => import('../pages/rolemethodlist/Page'))
  const RoleModelListPage = lazy(() => import('../pages/rolemodellist/Page'))
  const MenuListPage = lazy(() => import('../pages/menulist/Page'))
  const MenuRolePage = lazy(() => import('../pages/menurole/Page'))
  const UserActionPage = lazy(() => import('../pages/useraction/Page'))
  const CulturePage = lazy(() => import('../pages/culture/Page'))
  const TranslationPage = lazy(() => import('../pages/translation/Page'))
  const ApplicationRolePage = lazy(() => import('../pages/applicationrole/Page'))
  const ApplicationUserRolePage = lazy(() => import('../pages/applicationuserrole/Page'))
  const ApplicationUserClaimPage = lazy(() => import('../pages/applicationuserclaim/Page'))
  const ApplicationRoleClaimPage = lazy(() => import('../pages/applicationroleclaim/Page'))
  const TenantPage = lazy(() => import('../pages/tenant/Page'))
  const CodePage = lazy(() => import('../pages/code/Page'))
  const OrganizationPage = lazy(() => import('../pages/organization/Page'))
  const TicketPage = lazy(() => import('../pages/ticket/Page'))
  const TicketReplyPage = lazy(() => import('../pages/ticketreply/Page'))
  const ApplicationUserPage = lazy(() => import('../pages/applicationuser/Page'))
  const ChildrenPage = lazy(() => import('../pages/children/Page'))
  const HourlyPricePage = lazy(() => import('../pages/hourlyprice/Page'))
  const PackagePage = lazy(() => import('../pages/package/Page'))
  const CustomerPackagePage = lazy(() => import('../pages/customerpackage/Page'))
  const ProductCategoryPage = lazy(() => import('../pages/productcategory/Page'))
  const ProductPage = lazy(() => import('../pages/product/Page'))
  const TransactionPage = lazy(() => import('../pages/transaction/Page'))
  const AccountingPage = lazy(() => import('../pages/accounting/Page'))
  const MessagePage = lazy(() => import('../pages/message/Page'))
  const BirthDayPage = lazy(() => import('../pages/birthDay/Page'))
  const PackageReportPage = lazy(() => import('../pages/PackageDebt/Page'))
  const DebtReportPage = lazy(() => import('../pages/debtReport/Page'))
  const AccounttingReportPage = lazy(() => import('../pages/accountingReport/Page'))
  const AccounttingExpensReportPage = lazy(() => import('../pages/accountingExpensReport/Page'))
  const DiscountPage = lazy(() => import('../pages/discount/Page'))
  const BreaktimePage = lazy(() => import('../pages/breaktime/Page'))
  const AccounttingDailyPage = lazy(() => import('../pages/accountingDailyReport/Page'))
  const ToBankPage = lazy(() => import('../pages/tobank/Page'))
  const SpendToBankPage = lazy(() => import('../pages/spendtobank/Page'))

  return (
    <Routes>
      <Route element={<MasterLayout {...(menus || [])} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='auth/*' element={<Navigate to='/' />} />
        <Route path='dashboard' element={<DashboardPage />} />
        <Route path='adisyon' element={<AdisyonDetail />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='bankreport/*'
          element={
            <SuspensedView>
              <ToBankPage />
            </SuspensedView>
          }
        />
        <Route
          path='spendtobank/*'
          element={
            <SuspensedView>
              <SpendToBankPage />
            </SuspensedView>
          }
        />
        <Route
          path='birthday/*'
          element={
            <SuspensedView>
              <BirthDayPage />
            </SuspensedView>
          }
        />
        <Route
          path='breaktime/*'
          element={
            <SuspensedView>
              <BreaktimePage />
            </SuspensedView>
          }
        />

        <Route
          path='discount/*'
          element={
            <SuspensedView>
              <DiscountPage />
            </SuspensedView>
          }
        />

        <Route
          path='accountingReport/*'
          element={
            <SuspensedView>
              <AccounttingReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='accountingexpensereport/*'
          element={
            <SuspensedView>
              <AccounttingExpensReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='accountingDailyReport/*'
          element={
            <SuspensedView>
              <AccounttingDailyPage />
            </SuspensedView>
          }
        />

        <Route
          path='report/*'
          element={
            <SuspensedView>
              <PackageReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='debtreport/*'
          element={
            <SuspensedView>
              <DebtReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='modellist/*'
          element={
            <SuspensedView>
              <ModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='methodlist/*'
          element={
            <SuspensedView>
              <MethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemethodlist/*'
          element={
            <SuspensedView>
              <RoleMethodListPage />
            </SuspensedView>
          }
        />
        <Route
          path='rolemodellist/*'
          element={
            <SuspensedView>
              <RoleModelListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menulist/*'
          element={
            <SuspensedView>
              <MenuListPage />
            </SuspensedView>
          }
        />
        <Route
          path='menurole/*'
          element={
            <SuspensedView>
              <MenuRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='useraction/*'
          element={
            <SuspensedView>
              <UserActionPage />
            </SuspensedView>
          }
        />
        <Route
          path='culture/*'
          element={
            <SuspensedView>
              <CulturePage />
            </SuspensedView>
          }
        />
        <Route
          path='translation/*'
          element={
            <SuspensedView>
              <TranslationPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationrole/*'
          element={
            <SuspensedView>
              <ApplicationRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserrole/*'
          element={
            <SuspensedView>
              <ApplicationUserRolePage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationuserclaim/*'
          element={
            <SuspensedView>
              <ApplicationUserClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='applicationroleclaim/*'
          element={
            <SuspensedView>
              <ApplicationRoleClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='tenant/*'
          element={
            <SuspensedView>
              <TenantPage />
            </SuspensedView>
          }
        />
        <Route
          path='code/*'
          element={
            <SuspensedView>
              <CodePage />
            </SuspensedView>
          }
        />
        <Route
          path='organization/*'
          element={
            <SuspensedView>
              <OrganizationPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticket/*'
          element={
            <SuspensedView>
              <TicketPage />
            </SuspensedView>
          }
        />
        <Route
          path='ticketreply/*'
          element={
            <SuspensedView>
              <TicketReplyPage />
            </SuspensedView>
          }
        />
        <Route
          path='customer/*'
          element={
            <SuspensedView>
              <ApplicationUserPage />
            </SuspensedView>
          }
        />
        <Route
          path='children/*'
          element={
            <SuspensedView>
              <ChildrenPage />
            </SuspensedView>
          }
        />
        <Route
          path='price/*'
          element={
            <SuspensedView>
              <HourlyPricePage />
            </SuspensedView>
          }
        />
        <Route
          path='package/*'
          element={
            <SuspensedView>
              <PackagePage />
            </SuspensedView>
          }
        />
        <Route
          path='customerpackage/*'
          element={
            <SuspensedView>
              <CustomerPackagePage />
            </SuspensedView>
          }
        />
        <Route
          path='productcategory/*'
          element={
            <SuspensedView>
              <ProductCategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='product/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/*'
          element={
            <SuspensedView>
              <TransactionPage />
            </SuspensedView>
          }
        />
        <Route
          path='accounting/*'
          element={
            <SuspensedView>
              <AccountingPage />
            </SuspensedView>
          }
        />
        <Route
          path='message/*'
          element={
            <SuspensedView>
              <MessagePage />
            </SuspensedView>
          }
        />
        <Route
          path='dashboard/*'
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
      </Route>
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
