import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import React, {FC, useState, useEffect} from 'react'
import {getTransactionByEndDate} from '../transaction/list/core/_requests'
import moment from 'moment'
import {KTIcon} from '../../../_metronic/helpers'
import qs from 'qs'
import {getChildrenById} from '../children/list/core/_requests'
import {getApplicationUserById} from '../applicationuser/list/core/_requests'
import {getTransactionById} from '../transaction/list/core/_requests'
import {listProduct} from '../product/list/core/_requests'
import {UpdateDateModal} from './UpdateDateModal'
import {
  createProductTransaction,
  getProductTransactionByTransactionId,
  deleteProductTransaction,
  closeProductsBill,
  closeBill,
} from '../transaction/list/core/_requests'
import {listCode} from '../code/list/core/_requests'
import {Navigate, useNavigate} from 'react-router-dom'
import {AddTimeout} from './AddTimeout'
import {listHourlyPrice} from '../hourlyprice/list/core/_requests'
import {getBreakTimeByTransactionId} from '../breaktime/list/core/_requests'

const AdisyonDetail = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [child, setChild] = React.useState<any>([])
  const [customer, setCustomer] = React.useState<any>([])
  const [transaction, setTransaction] = React.useState<any>([])
  const [currentTime, setCurrentTime] = useState(new Date())
  const [timeTypeMinute, setTimeTypeMinute] = React.useState<any>([])
  const [extraPayment, setExtraPayment] = React.useState<any>(undefined)
  const [product, setProduct] = React.useState<any>([])
  const [selectedProduct, setSelectedProduct] = React.useState<any>(undefined)
  const [productCount, setProductCount] = React.useState<any>(undefined)
  const [basket, setBasket] = React.useState<any>([])
  const [totalBasketPrice, setTotalBasketPrice] = React.useState<any>([])
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [showCreateAppModal2, setShowCreateAppModal2] = useState<boolean>(false)
  const [triger, setTriger] = useState<boolean>(false)
  const [paymentTypes, setPaymentTypes] = useState<any>([])
  const [paymentTypes2, setPaymentTypes2] = useState<any>([])
  const [isDebt, setIsDebt] = useState<boolean>(false)
  const [selectedPaymentType, SetSelectedPaymentType] = useState<any>(undefined)
  const [selectedPaymentType2, SetSelectedPaymentType2] = useState<any>(undefined)
  const [payFromMember, setPayFromMember] = useState<boolean>(false)
  const [hourlyprice, setHourlyprice] = useState<any>(undefined)
  const [totalBreakTime, setTotalBreakTime] = useState<any>(0)

  const qsd3 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).adisyonId ?? undefined

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined
    const qsd2 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).childrenId ?? undefined
    const qsd3 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).adisyonId ?? undefined

    listHourlyPrice('Id,Name,Price').then((res4: any) => {
      setHourlyprice(res4.data)
    })

    listCode('Id,Name,type').then((res8: any) => {
      const nonMembershipItems2 = res8?.data.filter((item: any) => item.type == 'PaymentType')

      setPaymentTypes2(nonMembershipItems2)
      const nonMembershipItems = res8?.data.filter(
        (item: any) => item.type == 'PaymentType' && item?.name != 'Üyelik'
      )

      // Filtrelenmiş ürünleri sepete ekleyin
      setPaymentTypes(nonMembershipItems)
    })

    getChildrenById(qsd2).then((res: any) => {
      setChild(res)
    })

    getApplicationUserById(qsd).then((res2: any) => {
      setCustomer(res2)
    })

    getTransactionById(qsd3).then((res3: any) => {
      setTransaction(res3)
    })

    listProduct('Id,Name,Price,Amount').then((res4: any) => {
      const filteredData = res4.data.filter(
        (item: any) => item.amount !== '0' && item.amount !== null
      )
      console.log(filteredData)
      setProduct(filteredData)
    })
  }, [])

  useEffect(() => {
    const qsd3 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).adisyonId ?? undefined
    getProductTransactionByTransactionId(qsd3).then((res: any) => {
      setBasket(res.data)
    })
  }, [triger])

  const getElapsedTime = (startTime: Date): string => {
    const startDate = new Date(startTime)
    const timeDifference = currentTime.getTime() - startDate.getTime()

    // setTimeTypeMinute(minutesElapsed)
    const secondsElapsed = Math.floor(timeDifference / 1000)
    const hours = Math.floor(secondsElapsed / 3600)
    const minutes = Math.floor((secondsElapsed % 3600) / 60)
    return `${hours} saat ${minutes} dakika`
  }
  console.log(hourlyprice)
  useEffect(() => {
    const timer = setTimeout(() => {
      const dateObject = new Date(transaction?.startTime)
      const timeDifference2 = currentTime.getTime() - dateObject.getTime()
      const minutesElapsed =
        Math.floor(timeDifference2 / 60000) - (customer?.remainingTime + totalBreakTime)

      setTimeTypeMinute(minutesElapsed)
      console.log(customer?.remainingTime)
      console.log(minutesElapsed)
      console.log(totalBreakTime)
      if (0 < minutesElapsed) {
        if (minutesElapsed < 30) {
          setExtraPayment(hourlyprice[0].price)
        } else if (minutesElapsed >= 30 && minutesElapsed < 60) {
          setExtraPayment(hourlyprice[1].price)
        } else {
          // 60 dakikadan sonrası için her 30 dakika için +100 ekleyin
          const additionalMinutes = minutesElapsed - 60
          const extraPaymentAmount = Math.ceil(additionalMinutes / 30) * hourlyprice[2].price
          // if (extraPaymentAmount == 0) {
          //   setExtraPayment(200 + 100)
          // } else {
          //   setExtraPayment(200 + extraPaymentAmount)
          // }
          setExtraPayment(parseInt(hourlyprice[1].price) + extraPaymentAmount)
        }
      }
    }, 300) // 0.5 saniye (500 milisaniye) sonra çalışması için

    return () => clearTimeout(timer) // Temizleme işlevini gerçekleştir
  }, [transaction, customer, totalBreakTime])

  const selectProduct = (id: any) => {
    let x = product?.filter((item: any) => item?.id == id)
    setSelectedProduct(x)
  }

  const AddToBasket = () => {
    const qsd3 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).adisyonId ?? undefined
    let PTransaction = {
      productId: selectedProduct[0]?.id,
      transactionId: qsd3,
      piece: productCount,
    }

    createProductTransaction(PTransaction).then((res: any) => {
      setTriger(!triger)
    })

    // let obj = {
    //   productName: selectedProduct[0]?.name,
    //   productPrice: selectedProduct[0]?.price,
    //   count: Number(productCount),
    // }
    // setBasket((prevBasket: any) => [...prevBasket, obj])
  }

  // const removeItemAtIndex = (i: any) => {
  //   console.log(i)
  //   console.log(basket)
  //   setBasket((prevBasket: any) => {
  //     const updatedBasket = [...prevBasket]
  //     updatedBasket.splice(i, 1)
  //     return updatedBasket
  //   })
  // }

  const removeItemAtIndex = (itemId: number) => {
    deleteProductTransaction(itemId).then((res: any) => {
      setTriger(!triger)
    })
  }

  const total = basket?.reduce((acc: any, item: any) => {
    const price = Number(item.product.price)
    const count = item.piece

    return acc + price * count
  }, 0)

  useEffect(() => {
    setTotalBasketPrice(total)
  }, [basket])

  const CloseProductAdisyon = () => {
    const qsd3 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).adisyonId ?? undefined

    if (basket.length > 0) {
      let x = {
        transactionId: qsd3,
        paymentTypeId: selectedPaymentType,
      }
      closeProductsBill(x).then((res: any) => {
        let y = {
          paymentTypeId: selectedPaymentType2,
          isDebt: isDebt,
          payMembership: payFromMember,
        }
        closeBill(y, qsd3).then((res: any) => {
          navigate(`/dashboard`)
        })
      })
    } else {
      let y = {
        paymentTypeId: selectedPaymentType2,
        isDebt: isDebt,
        payMembership: payFromMember,
      }
      closeBill(y, qsd3).then((res: any) => {
        navigate(`/dashboard`)
      })
    }
  }

  useEffect(() => {
    getBreakTimeByTransactionId(transaction?.id).then((res: any) => {
      console.log(res)
      var totalDurationMinutes = res?.data
        .map((item: any) => {
          var endTime: any = new Date(item.endTime)
          var startTime: any = new Date(item.startTime)

          console.log(item.startTime)
          console.log(item.endTime)

          if (item.startTime != null && item.endTime != null) {
            var timeDifference = endTime - startTime
            var durationMinutes = timeDifference / (1000 * 60)
          } else {
            durationMinutes = 0
          }

          // milliseconds to minutes

          return durationMinutes
        })
        .reduce((total: any, duration: any) => total + duration, 0)
      console.log(totalDurationMinutes)
      setTotalBreakTime(totalDurationMinutes)
    })
  }, [transaction])

  console.log(timeTypeMinute)
  console.log(customer)
  console.log(transaction)
  console.log(extraPayment)

  // console.log(getElapsedTime(transaction?.startTime))
  // console.log(timeDifference2)

  const dateObject = new Date(transaction?.startTime)
  const timeDifference3 = currentTime.getTime() - dateObject.getTime()
  const timeDifference4 = Math.floor(timeDifference3 / 60000)
  console.log(timeDifference4)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Adisyon Detay'})}</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Çocuk Adı : {child?.name}
                    </a>
                    <a
                      className='text-hover-primary fs-2 fw-bolder me-1 mx-2'
                      style={{color: '#f1416c'}}
                    >
                      {new Date(child?.birthday).toDateString() === new Date().toDateString()
                        ? ' Doğum Günün Kutlu Olsun !'
                        : null}
                    </a>
                  </div>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Müşteri Adı : {customer?.name}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary m-2' />
                    </a>

                    {/* <button type='button' className='btn btn-sm btn-primary  '>
                    Çocuk Ekle
                  </button> */}
                  </div>

                  <div className='d-flex flex-wrap fw-bold text-gray-400 fs-6 mb-4 pe-2 flex-column'>
                    <p>Tel-1 : {customer?.phone1}</p>
                    <p>Tel-2 : {customer?.phone2}</p>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        {/* <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' /> */}
                        <div className='fs-2 fw-bolder'>{customer?.remainingTime} Dk</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Paket Süre</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='outer-card mb-16 mt-16' style={{padding: '32px', backgroundColor: '#ffc700'}}>
        <div className='fs-3 fw-bold text-black-400 mb-4 '>Kullanım Bilgileri</div>

        <div className='inner-card' style={{padding: '32px', backgroundColor: '#ffffff'}}>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='fs-3 fw-bold text-black-400 mb-4 '>Kullanım Durumu</div>
              </div>
            </div>
          </div>
          <div
            className=' d-flex flex-column'
            style={{borderBottom: '1px solid #000', paddingBottom: '20px'}}
          >
            <div className='d-flex flex-row gap-10 mt-7'>
              <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                Başlangıç Saati : {moment(transaction?.startTime).format('YYYY-MM-DD HH:mm')}
              </div>
              <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                {transaction?.endTime
                  ? `Bitiş Saati: ${moment(transaction?.endTime).format('YYYY-MM-DD HH:mm')}`
                  : 'Bitiş Saati : -'}
              </div>
              <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                Kullanım Süresi : {getElapsedTime(transaction?.startTime)}{' '}
              </div>
              <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                Mola Süresi : {totalBreakTime} dakika
              </div>
            </div>

            <div className='d-flex flex-row gap-4'>
              <button
                type='button'
                className='btn btn-sm btn-warning btn-active-light-primary mt-4  '
                style={{width: '150px'}}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                id='kt_toolbar_primary_button'
                onClick={() => setShowCreateAppModal(true)}
              >
                Saat Düzenle
              </button>
              <UpdateDateModal
                item={transaction.id}
                show={showCreateAppModal}
                handleClose={() => setShowCreateAppModal(false)}
                // setTrigger={setTrigger}
              />
              <Link to={`/breaktime/list?adisyonId=${qsd3}`}>
                <button
                  type='button'
                  className='btn btn-sm btn-warning btn-active-light-primary mt-4  '
                  style={{width: '150px'}}
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_app'
                  id='kt_toolbar_primary_button'
                  // onClick={() => setShowCreateAppModal2(true)}
                >
                  Mola Düzenle
                </button>
              </Link>
              {/* <AddTimeout
                item={transaction.id}
                show={showCreateAppModal2}
                handleClose={() => setShowCreateAppModal2(false)}
                // setTrigger={setTrigger}
              /> */}
              <div className='fs-4 fw-semibold text-black-400  mt-4 px-4 '>
                Notlar : {customer?.notes}{' '}
              </div>
            </div>
          </div>
          <div className=' d-flex flex-column mt-7 '>
            {customer?.remainingTime < timeDifference4 ? (
              <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                Üyeliği Aşan Süre : {timeTypeMinute} dk
              </div>
            ) : (
              <div className='fs-4 fw-semibold text-black-400 mb-4 '>Üyeliği Aşan Süre : - </div>
            )}

            <div className='fs-4 fw-semibold text-black-400 mb-4 '>
              {extraPayment == undefined
                ? ' Ücret : - '
                : transaction?.withParent
                ? ` Üyeliği Aşan Ücret : ${parseInt(extraPayment) * 0.9} TL`
                : ` Üyeliği Aşan Ücret : ${extraPayment} TL`}
              {extraPayment != undefined && transaction?.withParent ? (
                <p>(Ebeveynli İndirimi)</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className=' row flex-column d-flex mb-32 flex-row px-5'
        style={{justifyContent: 'space-between', minHeight: '300px'}}
      >
        <div className='card mb-5 mb-xl-10 ' style={{width: '100%'}}>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='w-100 mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'Ürünler'})}
                    </label>
                    <select
                      className='form-select form-select-solid form-select-md'
                      onChange={(e: any) => selectProduct(e.target.value)}
                    >
                      <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
                      {product?.map((myval: any) => (
                        <option value={myval.id} key={myval?.id as any}>
                          {myval?.name as any}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='w-100 mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>
                      {intl.formatMessage({id: 'Ürün Adet'})}
                    </label>
                    <input
                      type='number'
                      name='name'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      onChange={(e: any) => setProductCount(e.target.value)}
                    />
                  </div>

                  <button
                    className='btn btn-sm btn-warning btn-active-light-primary mt-4  '
                    style={{width: '150px'}}
                    onClick={(e: any) => AddToBasket()}
                    disabled={selectedProduct == undefined || productCount == undefined}
                  >
                    Ekle
                  </button>
                </div>

                <div
                  className='d-flex justify-content-between align-items-start flex-wrap mb-2 mt-16'
                  style={{width: '100%'}}
                >
                  <div
                    className='d-flex flex-wrap fw-bold text-gray-400 fs-6 mb-4 pe-2 flex-column'
                    style={{
                      width: '100%',
                      borderTop: '1px solid #000',
                      paddingTop: '30px',
                      borderBottom: '1px solid #000',
                      paddingBottom: '10px',
                    }}
                  >
                    {basket?.map((item: any, i: any) => {
                      return (
                        <div className=' row w-100' style={{width: '100%'}}>
                          <p className='col-2'>Ürün {i + 1} </p>
                          <p className='col-2'> {item?.product?.name} </p>
                          <p className='col-3'> Birim Fiyat : {item?.product.price} TL </p>
                          <p className='col-2'> {item?.piece} Adet </p>
                          <p className='col-2'>
                            {' '}
                            Toplam : {item?.piece * Number(item?.product.price)} TL
                          </p>
                          <p
                            className='col-1 '
                            style={{
                              color: '#F20404',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                              cursor: 'pointer',
                            }}
                            onClick={() => removeItemAtIndex(item?.id)}
                          >
                            X
                          </p>
                        </div>
                      )
                    })}
                  </div>
                  <div className=' m-10 d-flex flex-row gap-16 row w-100'>
                    <p className='mt-5 fs-4 fw-semibold text-black-400 mb-4 col-3 pt-5 '>
                      Ürün Fiyatı : {totalBasketPrice} TL
                    </p>

                    <div className=' mb-7 col-3'>
                      <label className='required fw-bold fs-6 mb-2'>
                        {intl.formatMessage({id: 'Ödeme Tipi'})}
                      </label>
                      <select
                        className='form-select form-select-solid form-select-md'
                        onChange={(e: any) => SetSelectedPaymentType(e.target.value)}
                      >
                        <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
                        {paymentTypes?.map((myval: any) => (
                          <option value={myval.id} key={myval?.id as any}>
                            {myval?.name as any}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className=' m-10 d-flex flex-row gap-16 row w-100'>
                    <p className='mt-5 fs-4 fw-semibold text-black-400 mb-4 col-3 pt-5 '>
                      {/* {customer?.remainingTime < timeTypeMinute ? (
                        <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                          Üyeliği Aşan Süre : {timeTypeMinute - customer?.remainingTime} dk
                        </div>
                      ) : (
                        <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                          Üyeliği Aşan Süre : -{' '}
                        </div>
                      )} */}

                      <div className='fs-4 fw-semibold text-black-400 mb-4 '>
                        {extraPayment == undefined
                          ? ' Ücret : - '
                          : transaction?.withParent
                          ? ` Üyeliği Aşan Ücret : ${parseInt(extraPayment) * 0.9} TL`
                          : ` Üyeliği Aşan Ücret : ${extraPayment} TL`}
                        {extraPayment != undefined && transaction?.withParent ? (
                          <p>(Ebeveynli İndirimi)</p>
                        ) : null}
                      </div>
                    </p>
                    {extraPayment == undefined ? (
                      <div className=' mb-7 col-3'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'Ödeme Tipi'})}
                        </label>
                        <select
                          className='form-select form-select-solid form-select-md'
                          onChange={(e: any) => SetSelectedPaymentType2(e.target.value)}
                        >
                          <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
                          {paymentTypes?.map((myval: any) => (
                            <option value={myval.id} key={myval?.id as any}>
                              {myval?.name as any}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <div className=' mb-7 col-3'>
                        <label className='required fw-bold fs-6 mb-2'>
                          {intl.formatMessage({id: 'Ödeme Tipi'})}
                        </label>
                        <select
                          className='form-select form-select-solid form-select-md'
                          onChange={(e: any) => SetSelectedPaymentType2(e.target.value)}
                        >
                          <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
                          {paymentTypes?.map((myval: any) => (
                            <option value={myval.id} key={myval?.id as any}>
                              {myval?.name as any}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className='form-check form-check-solid form-switch fv-row col-2 mt-7'>
                      <input
                        checked={isDebt}
                        onChange={(e) => setIsDebt(e.target.checked)}
                        // value={formik.values.isExercise ? 'on' : 'off'}
                        className='form-check-input w-50px mt-2 border-secondary'
                        type='checkbox'
                        id='isExercise'
                      />
                      <label className='form-check-label mt-2 fs-6 px-5 d-block'>
                        <small className='text-danger'>Ödemeyi müşteri carisine at</small>
                      </label>
                    </div>
                    {customer?.remainingTime != null ? (
                      <div className='form-check form-check-solid form-switch fv-row col-2 mt-7'>
                        <input
                          checked={payFromMember}
                          onChange={(e) => setPayFromMember(e.target.checked)}
                          // value={formik.values.isExercise ? 'on' : 'off'}
                          className='form-check-input w-50px mt-2 border-secondary'
                          type='checkbox'
                          id='isExercise'
                        />
                        <label className='form-check-label fs-4 mt-2 px-5 d-block'>
                          <small className='text-danger'>Üyelikten Öde</small>
                        </label>
                      </div>
                    ) : null}
                  </div>
                  <div className='d-flex w-100 justify-content-center mb-4'>
                    <button
                      className='btn btn-sm btn-warning btn-active-light-primary    col-3 '
                      style={{width: '150px', height: '40px'}}
                      onClick={(e: any) => CloseProductAdisyon()}
                      // disabled={selectedPaymentType == undefined}
                    >
                      Adisyonu Kapat
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AdisyonDetail}
